import * as React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Nav, Navbar } from "react-bootstrap"
import { Link } from "../../.cache/gatsby-browser-entry"
import {
  trackingEvent,
  CLICK_ACTION,
  HEADER_BUTTON_CATEGORY,
  HEADER_LOGO_LABEL,
  HEADER_INQUIRY_LABEL,
  HEADER_TRIAL_LABEL,
} from "../tools/Tracking"

const Header = ({ siteTitle }) => (
  <header>
    <Container className="container">
      <Navbar expand="lg" variant="dark">
        <Navbar.Brand href="/">
          <StaticImage
            src="../images/logo.png"
            alt="ワカチエロゴ"
            className="wakachieLogo"
            onClick={() =>
              trackingEvent(
                CLICK_ACTION,
                HEADER_BUTTON_CATEGORY,
                HEADER_LOGO_LABEL
              )
            }
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarResponsive" />
        <Navbar.Collapse id="navbarResponsive">
          <Nav as="ul" className="ml-auto">
            <Nav.Item as="li">
              <Link to="/feature" className="" activeClassName="active">
                機能・特徴
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link to="/usecase" className="" activeClassName="active">
                利用シーン
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link to="/plan" className="" activeClassName="active">
                料金プラン
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link to="/inquiry/" className="accentButton inquiry" activeClassName="active">
                資料請求
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link className="accentButton trial" activeClassName="active" to="/trial/">
                無料トライアル
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
