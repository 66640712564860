//google Analyticsのトラッキングイベントを関数として呼び出せるようにしています。

export const trackingEvent = (action, category, label, value) => {
  if (window.gtag) {
    try {
      window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value: value,
      })
    } catch (error) {
      console.log("tracking event sending fail")
      console.log(error.message)
    }
  }
}

//イベントアクションを定義する定数
export const CLICK_ACTION = "Click"
export const CLICK_TEXT_BOX_ACTION = "ClickTextBox"
export const SELECT_RADIO_ACTION = "SelectRadio"
export const SELECT_CHECK_ACTION = "SelectCheck"
export const SUBMIT_FORM_ACTION = "SubmitForm"

//イベントカテゴリを定義した定数
export const TOP_PAGE_CATEGORY = "TopPage"
export const TRIAL_FORM_CATEGORY = "TrialForm"
export const INQUIRY_FORM_CATEGORY = "InquiryForm"
export const CONTACT_FORM_CATEGORY = "ContactForm"
export const HEADER_BUTTON_CATEGORY = "Header"
export const DOWNLOAD_PAGE_CATEGORY = "DownloadPage"

//イベントラベルを定義する定数
export const TOP_IMAGE_LABEL = "TopImage"
export const TOP_TRIAL_LABEL = "TopTrial"
export const HEADER_LOGO_LABEL = "HeaderLogo"
export const HEADER_INQUIRY_LABEL = "HeaderInquiry"
export const HEADER_TRIAL_LABEL = "HeaderTrial"
export const BANNER_INQUIRY_LABEL = "BannerInquiry"
export const BANNER_TRIAL_LABEL = "BannerTrial"
export const PRODUCTDOCUMENT_DOWNLOAD_LABEL = "ProductDocumentDownload"
export const WHIEPAPER_DOWNLOAD_LABEL = "WhitePaperDownload"

